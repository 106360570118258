// import * as React from 'react'
// import { Link } from 'gatsby'
// import { PrismicProvider } from '@prismicio/react'
// import { linkResolver } from './src/utils/linkResolver'
// import CombinedProvider from './src/context/CombinedProvider'

//  export const wrapRootElement = ({ element }) => {
//    return (  
//      <PrismicProvider
//        linkResolver={linkResolver}
//        //richTextComponents={components}
//        internalLinkComponent={({ href, ...props }) => (
//          <Link to={href} {...props} />
//        )}
//      >
//        {element}
//      </PrismicProvider>
//  )
// }

import CombinedProvider from "./src/context/CombinedProvider"

export const wrapRootElement = CombinedProvider